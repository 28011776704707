﻿require('parsley');
// google captcha v3
const recaptcha = (formId, action) => {
  grecaptcha.enterprise.ready(function () {
    $('#' + formId).on('submit', function (event) {
      event.preventDefault();

      $('#' + formId + ' [data-parsley-id]').each(function () {
        //Validate form fields first (prevents insconsistent validation issues)
        $(this).attr('data-parsley-errors-messages-disabled', 'true');
        $(this).parsley().validate();
      });

      //Validate form as a whole
      $(this).parsley().validate();

      if($(this).parsley().isValid() && $(this).find('input.parsley-error, .form-group.has-error input').length <= 0) {
        document.querySelector('#' + formId + ' input[type="submit"], button[type="submit"]').disabled = true;
        grecaptcha.enterprise.execute(window.recaptchaPublicKey, { action: action }).then(function (token) {
          if (!document.getElementsByName('captchaToken').length) {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', 'captchaToken');
            input.setAttribute('id', 'captchaToken');
            // add token to the form so server can validate
            document.getElementById(formId).appendChild(input);
          }
          document.getElementById('captchaToken').value = token;
          document.getElementById(formId).submit();
        });
      }
    });
  });
};

module.exports = recaptcha;
